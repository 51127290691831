<template>
<div>
      <!-- Breadcrumb start -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Reports</li>
      </ol>
      <!-- Breadcrumb end -->
  <b-card no-body>
    <b-tabs card>
      <b-tab title="Time">
        <b-row>
         <b-col cols="2">
          <b-dropdown variant="primary" :text="'FY' + reportSelectedYear">
              <b-dropdown-item 
                v-for="index in (fyEnd - fyStart + 1)" 
                :key="index" 
                :active="reportSelectedYear == ( fyStart + index - 1 )"
                @click="changeYear(fyStart + index - 1 )">
                FY{{ fyStart + index - 1 }}
              </b-dropdown-item>
            </b-dropdown>
           <div v-for="(report, index) in reports" :key="index">
             <ul>
               <li> <b-icon icon="arrow-right-short" variant="primary"></b-icon><b-link :href="buildLink(report.link)" target="core">{{ report.name }}</b-link></li>
             </ul>
             <!--b-link :href="buildLink(report.link)" target="_blank">{{ report.name }}</b-link-->
           </div>
         </b-col>
         <b-col class="reports-container">
           <iframe id="coreIframe" name="core" style="width: 100%;height:100%;background:#f5f5f5; border: 0px;"></iframe>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Billing">
      </b-tab>
      <b-tab title="Projects">
      </b-tab>
      <b-tab title="Revenues">
      </b-tab>
   </b-tabs>
  </b-card>


</div>
</template>

<script>

import { getReports } from '@/api/reports.api'
import { getFiscalYears } from '@/api/invoices.api'
import crudMixin from '@/api/crud'
import httpClient from '@/api/httpClient'

export default {
    mixins: [crudMixin],
  data() {
    return {
      reports: [],
      reportCurrentYear: null,
      reportSelectedYear:null,
      fyStart: null,
      fyEnd: null,
      fyDate: null
    }
  },
  mounted() {
    this.getReports();
    this.reportCurrentYear = new Date().getFullYear() + 1;
    this.getAllYears();
  },
  methods: {
    buildLink(link) {
        return httpClient.defaults.baseURL + link + '?financialYear=' + this.reportSelectedYear + '&token=' + this.$store.getters.token;
    },
    getReports() {
        this.call(
            getReports(),
            (res) => {
                res.data.reports.forEach(item => {
                    this.reports.push(item);
                });

            }
        )
    },
    getAllYears() {
        this.call(
            getFiscalYears(),
            (res) => {
                this.fyStart = res.data.years[0]
                this.fyEnd = res.data.years[1]
                this.reportSelectedYear = this.fyEnd
            }
        )
    },
    changeYear(year){
      this.reportSelectedYear = year
      var iframe = document.getElementById('coreIframe')
      var html = "";
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(html);
      iframe.contentWindow.document.close();
    }
  },
  computed: {
    financialYearStart: function() {
        if(this.fyDate == null) {
            return ''
        }
        return this.fyDate.toLocaleString('default', { month: 'long' }) + " " + this.fyDate.getDate();
    }
  },
}

</script>
<style scoped>
li {
  list-style: none;
  position: relative;
  padding: 0;
}
ul{
  margin: 0;
  padding: 0;
}
.reports-container{
  min-height: 500px;
}
</style>